import { Box, Heading, Flex, Text } from '@chakra-ui/react';
import { Container } from '../../../components';
import { ReactComponent as SvgFireBase } from '../../../assets/svg_by_firebase.svg';
import { ReactComponent as SvgKotlin } from '../../../assets/svg_by_kotlin.svg';
import { ReactComponent as SvgCloudFunction } from '../../../assets/svg_by_cloud_functions.svg';
import styled from '@emotion/styled';

const SvgBox = styled(Box)({
  height: '100px',
  display: 'flex',
  flexDirection: 'column',
  justifyItems: 'center',
  margin: '20px 30px',
  '& > svg': {
    height: '100%',
  },
});
const Powered = () => {
  return (
    <Container
      as="section"
      bg="linear-gradient(162.44deg, #1DA7EA 0%, #00D2FF 100%)"
    >
      <Box pt="3rem" pb="3rem" textAlign="center">
        <Heading color="white" size="2xl" fontWeight="bold">
          Powered by
        </Heading>
        <Flex pt="3rem" justifyContent="center" flexDirection={['column', 'column', 'row']}>
          <SvgBox>
            <SvgFireBase />
            <Text color="white" size="sm" pt="10px">
              Firebase
            </Text>
          </SvgBox>
          <SvgBox>
            <SvgKotlin />
            <Text color="white" size="sm" pt="10px">
              Kotlin Coroutines
            </Text>
          </SvgBox>
          <SvgBox>
            <SvgCloudFunction />
            <Text color="white" size="sm" pt="10px">
                Cloud Functions
            </Text>
          </SvgBox>
        </Flex>
      </Box>
    </Container>
  );
};

export default Powered;
