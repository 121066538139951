/** @jsxImportSource @emotion/react */
import { Header } from '../components';
import Footer from '../components/Footer/Footer';
import Landing from './sections/Landing/Landing';
import LiveTyping from './sections/LiveTyping/LiveTyping';
import Powered from './sections/Powered/Powered';

const Home = () => {
  return (
    <>
      <Header />
      <Landing />
      <LiveTyping />
      <Powered />
      <Footer />
    </>
  );
};

export default Home;
