/** @jsxImportSource @emotion/react */

import { Box, Heading } from '@chakra-ui/react';
import { Container } from '..';

const Footer = () => {
  return (
    <Container>
      <Box py="3rem" textAlign="center" color="gray.500">
        <Heading size="md" fontWeight="medium" mb="5">
          {' '}
          Built with love in India 🇮🇳;
        </Heading>
        <Heading size="sm" fontWeight="medium">
          Copyrights © 2021{' '}
          <a
            href="https://www.linkedin.com/in/jeevan-surendran-09236b88/"
            css={{ textDecoration: 'underline', textDecorationThickness:'1.5px' }}
            target="_blank"
            rel="noreferrer"
          >
            Jeevan Surendran
          </a>
          . All Rights Reserved.
        </Heading>
      </Box>
    </Container>
  );
};

export default Footer;
