import {  Flex } from '@chakra-ui/react';
import { Container } from '..';
import { ReactComponent as HeaderLogo } from '../../assets/svg_header_logo.svg';

const Header = () => {
  return (
    <Container as="header" pos="absolute">
      <Flex h="5rem" align="center" w="100%" >
        <HeaderLogo />
      </Flex>
    </Container>
  );
};

export default Header;
