/** @jsxImportSource @emotion/react */
import { Box, Flex, Heading, Img } from '@chakra-ui/react';
import { Container } from '../../../components';
import MobileMockup from '../../../assets/png_main_phone.png';
import { ReactComponent as SvgHomePlayStore } from '../../../assets/svg_home_gstore.svg';
import { openInNewTab } from '../../../helpers';

const Landing = () => {
  return (
    <Container
      as="section"
      bg="linear-gradient(162.44deg, #1DA7EA 0%, #00D2FF 100%)"
    >
      <Flex
        pt="5rem"
        width="100%"
        h="100%"
        flexDirection={['column', 'column', 'row']}
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex h="100%" justifyContent="center" alignItems="center">
          <Flex direction="column" alignItems={['center', 'center', 'start']}>
            <Heading
              color="white"
              size="2xl"
              mt="10"
              fontWeight="bold"
              maxW="600px"
              textAlign={['center', 'center', 'start']}
            >
              Add a spark and make your conversations magical ✨
            </Heading>

            <Heading
              color="white"
              size="md"
              mt="30px"
              fontWeight="normal"
              maxW="600px"
              textAlign={['center', 'center', 'start']}
            >
              Boop makes a unique twist in your chatting experience.
            </Heading>

            <Box h={['60px', '55px', '60px']} mb="30px" mt="10">
              <SvgHomePlayStore
                css={{
                  height: '100%',
                  cursor: 'pointer',
                  transition: 'all 300ms ease-in-out',
                  transformOrigin: ' 50% 50%',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
                onClick={() =>
                  openInNewTab(
                    'https://play.google.com/store/apps/details?id=com.silverpants.instantaneous&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                  )
                }
              />
            </Box>
          </Flex>
        </Flex>
        <Img
          maxW="calc(100vw - 10rem)"
          minW="350px"
          maxH="85vh"
          src={MobileMockup}
        />
      </Flex>
    </Container>
  );
};


export default Landing;