/** @jsxImportSource @emotion/react */
import { Flex, Heading, Box } from '@chakra-ui/react';
import { Container } from '../../../components';
import { ReactComponent as SvgLiveTyping } from '../../../assets/svg_live_live.svg';

const LiveTyping = () => {
  return (
    <Container as="section">
      <Flex
        pt="3rem"
        width="100%"
        minH="60vh"
        flexDirection={['column', 'column', 'row']}
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex direction="column" alignItems={['center', 'center', 'start']}>
          <Heading
            size="2xl"
            mt="10"
            fontWeight="bold"
            color="#333333"
            maxW="600px"
            textAlign={['center', 'center', 'start']}
          >
            Skip the send button, Live typing to the rescue
          </Heading>
          <Heading
            size="md"
            mt="10"
            fontWeight="normal"
            color="#333333"
            maxW="500px"
            textAlign={['center', 'center', 'start']}
          >
            Powered by Firebase Firestore, anything you type is instantly
            transfered and displayed live. 
          </Heading>
          <Heading
            size="md"
            mt="10"
            fontWeight="normal"
            color="#333333"
            maxW="500px"
            textAlign={['center', 'center', 'start']}
          >
            Live messages are sampled to 50 milliseconds. Upto 200 last send messages are visible
          </Heading>
        </Flex>
        <Box maxW="calc(100vw - 10rem)" minW="350px" h="80vh">
          <SvgLiveTyping css={{ width: '100%', height: '100%' }} />
        </Box>
      </Flex>
    </Container>
  );
};

export default LiveTyping;
