import "@fontsource/dm-sans";
import "@fontsource/dm-sans/500.css";
import "@fontsource/dm-sans/700.css";
import "@fontsource/dm-sans/700-italic.css";

import React from 'react';
import {
  ChakraProvider,
} from '@chakra-ui/react';
import Home from './Home/Home';
import theme from './theme'

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Home />
    </ChakraProvider>
  );
}

export default App;
