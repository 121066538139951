import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    heading: "DM Sans",
    body: "DM Sans",
  },
});

export default theme;
