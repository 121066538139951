import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

const Container = styled(Box)(({ theme }) => {
  const { breakpoints } = theme;
  return {
    padding: '0 2rem',

    [`@media screen and (min-width: ${breakpoints.sm})`]: {
      padding: '0 5%',
    },
    [`@media screen and (min-width: ${breakpoints['2xl']})`]: {
      padding: '0 12%',
    },
  };
});

export default Container;
